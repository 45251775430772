import {has, map} from "lodash";

export const mappedAddresses = (addresses) => {
    return map(addresses, address => (
        {
            'id': address.id,
            'companyId': address.companyId,
            'addressId': address.addressId,
            'address': address.address,
            'address1': address.address1,
            'address2': address.address2,
            'postalCode': address.postalCode,
            'city': address.city,
            'countryCode': address.countryCode,
            'default': address.default,
            'compound': address.compound
        }
    ))
}

// eslint-disable-next-line no-unused-vars
export const DAYS_ORDER = {
    'mon': 1,
    'tue': 2,
    'wed': 3,
    'thu': 4,
    'fri': 5,
    'sat': 6,
    'sun': 7
};

const DAYS = {
    'mon': {'nl': "Monday", 'fr': "Monday", 'en': "Monday"},
    'tue': {'nl': "Tuesday", 'fr': "Tuesday", 'en': "Tuesday"},
    'wed': {'nl': "Wednesday", 'fr': "Wednesday", 'en': "Wednesday"},
    'thu': {'nl': "Thursday", 'fr': "Thursday", 'en': "Thursday"},
    'fri': {'nl': "Friday", 'fr': "Friday", 'en': "Friday"},
    'sat': {'nl': "Saturday", 'fr': "Saturday", 'en': "Saturday"},
    'sun': {'nl': "Sunday", 'fr': "Sunday", 'en': "Sunday"},
}

export const formatDay = (day, lang = 'nl') => {
    const exists = has(DAYS, day);
    if (exists) {
        return DAYS[day][lang]
    }

    return day
}

export const orderedDaysObject = daysObj => {
    /***
     * Get {"wed": value,"mon": value,"tue": value, ...}
     Returns {"mon": value,"tue": value, "fri": value...}
     * ***/
    let tmp = [];
    Object.keys(daysObj).forEach(function (key) {
        let value = daysObj[key];
        let index = DAYS_ORDER[key.toLowerCase()];
        tmp[index] = {
            key: key,
            value: value
        };
    });

    let orderedData = {};
    tmp.forEach(function (obj) {
        orderedData[obj.key] = obj.value;
    });

    return orderedData
}

export const getElementDimensionsById = id => {
    const el = document.getElementById(id);
    if (el) {
        return {
            height: el.clientHeight,
            width: el.clientWidth
        }
    }
}